import { BrowserRouter as Router, Switch } from "react-router-dom";

import { ROUTES } from "routes";

import RouteWithSubRoutes from "components/routeWithSubRoutes";

export default function Index() {
  return (
    <>
      <Router>
        <Switch>
          {ROUTES.map((route, i) => {
            return <RouteWithSubRoutes key={i} {...route} />;
          })}
        </Switch>
      </Router>
    </>
  );
}
