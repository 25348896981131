import { memo } from "react";
import logo from "assets/logo.png";

import { ReactComponent as DiscordIcon } from "assets/discord.svg";
import { ReactComponent as TwitterIcon } from "assets/twitter.svg";
import { ReactComponent as InstagramIcon } from "assets/instagram.svg";

export default memo(function Footer() {
  return (
    <div className="py-24 text-center bg-black">
      <a href="#dashboard">
        <img
          alt="AKC"
          src={logo}
          width={70}
          height={70}
          loading="lazy"
          className="m-auto"
        />
      </a>
      <div className="flex justify-center gap-8 my-6">
        <a
          target="_blank"
          rel="noreferrer"
          href="http://Instagram.com/alphakongsclub"
        >
          <InstagramIcon />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="http://Twitter.com/alphakongsclub"
        >
          <TwitterIcon />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://discord.gg/alphakongsclub"
        >
          <DiscordIcon />
        </a>
      </div>
      <p className="text-2xl mb-4">
        © 2022 Alpha Kongs Club | All Rights Reserved
      </p>
      <a
        target="_blank"
        rel="noreferrer"
        className="text-2xl"
        href="https://www.alphakongsclub.com/terms-of-use-disclaimer"
      >
        Terms of use & Disclaimer
      </a>
    </div>
  );
});
