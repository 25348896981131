import { memo, useEffect } from "react";

import ProviderHOC from "hoc/provider";
import useConnect from "hooks/useConnect";

const ConnectWalletButton = memo(
  ({ provider, setProvider, isPrimary = true }) => {
    const isConnected = !!provider?.address;
    const [, isConnecting, doConnect, connection] = useConnect(provider);

    useEffect(() => {
      setProvider(connection);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connection]);

    return (
      <>
        {!isConnected && !isConnecting && (
          <div
            onClick={() => doConnect()}
            className={`wallet-btn outline-btn ${isPrimary ? "primary" : ""}`}
          >
            connect my wallet
          </div>
        )}
        {!isConnected && isConnecting && (
          <div
            className={`wallet-btn outline-btn ${isPrimary ? "primary" : ""}`}
          >
            Please confirm the connection.
          </div>
        )}
        {isConnected && provider.address && (
          <div
            className={`wallet-btn outline-btn ${isPrimary ? "primary" : ""}`}
          >
            {provider.address.slice(0, 20)} ...
          </div>
        )}
      </>
    );
  }
);

export default ProviderHOC(ConnectWalletButton);
