import { useEffect, useState, memo } from "react";
import { matchPath, Link, useLocation } from "react-router-dom";

import { ROUTES, INDEX_ROUTE, MARKETPLACE_ROUTE, RAFFLE_ROUTE } from "routes";

import logo from "assets/logo.png";
import ConnectWalletButton from "components/connectWalletBtn";

export default memo(function Header() {
  const { pathname } = useLocation();
  const [selectedMenu, setSelectedMenu] = useState({});

  useEffect(() => {
    let items = [];
    ROUTES.forEach((item) => {
      if (item.routes) {
        items = [...items, ...item.routes];
      } else {
        items.push(item);
      }
    });
    items.forEach((item) => {
      const matchedPath = matchPath(pathname, {
        exact: true,
        path: item.path,
      });
      if (matchedPath) {
        if (selectedMenu.name !== item.name) {
          setSelectedMenu(item);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  function getMenuClasses(itemName) {
    return `hover:text-primary secondary-font text-2xl lg:text-[18px] text-[16px] ${
      selectedMenu.name === itemName ? "text-primary" : ""
    }`;
  }

  return (
    <div className={`bg-lightBlack p-6 ${selectedMenu.name}-header`}>
      <div className="container gap-12 items-center justify-between w-full m-auto md:flex max-w-[128rem]">
        <div className="flex gap-12 items-center mb-6 lg:mb-0">
          <img alt="AKC" loading="lazy" width={60} src={logo} />
          <div className="flex gap-x-12 gap-y-4 uppercase flex-wrap">
            <Link to={INDEX_ROUTE} className={getMenuClasses("index")}>
              Dashboard
            </Link>
            <Link
              to={MARKETPLACE_ROUTE}
              className={getMenuClasses("marketplace")}
            >
              Marketplace
            </Link>
            <Link to={RAFFLE_ROUTE} className={getMenuClasses("raffle")}>
              Raffle
            </Link>
          </div>
        </div>
        <ConnectWalletButton />
      </div>
    </div>
  );
});
