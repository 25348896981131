import { useMoralis } from "react-moralis";

import Index from "pages";

const App = () => {
  const { isInitialized } = useMoralis();

  return <div className="app-wrapper">{isInitialized && <Index />}</div>;
  //return <div>Page under maintenance</div>
};

export default App;
