import Footer from "components/footer";
import Header from "components/header";
import RenderRoutes from "components/renderRoutes";

export default function Wrapper({ routes }) {
  return (
    <div>
      <Header />
      <div className="app-container">
        <RenderRoutes routes={routes} />
      </div>
      <Footer />
    </div>
  );
}
