import { lazy } from "react";

import Wrapper from "components/wrapper";

export const INDEX_ROUTE = "/";
export const RAFFLE_ROUTE = "/raffle/";
export const MARKETPLACE_ROUTE = "/marketplace/";

const RaffleComponent = lazy(() => import("pages/raffle"));
const DashboardComponent = lazy(() => import("pages/dashboard"));
const MarketplaceComponent = lazy(() => import("pages/marketplace"));

export const ROUTES = [
  {
    path: "/",
    name: "index",
    component: Wrapper,
    routes: [
      {
        exact: true,
        name: "index",
        path: INDEX_ROUTE,
        component: DashboardComponent,
      },
      {
        exact: true,
        name: "marketplace",
        path: MARKETPLACE_ROUTE,
        component: MarketplaceComponent,
      },
      {
        exact: true,
        name: "raffle",
        path: RAFFLE_ROUTE,
        component: RaffleComponent,
      },
    ],
  },
];
