export const EARNINGS_PERIOD = 86400;
export const LS_MAPPING_KEY = "64bf9bd121662f86d2760cf1f95e8c7b";
export const FEEDBACK_URL = `https://akc-game-feedback-server-6hwqvwn6bq-uc.a.run.app/submit`;
export const ALPHA_COIN_BUY_URL = `https://app.uniswap.org/#/swap?chain=mainnet&outputCurrency=0x95D77c39397978204F84aD9aa5aEcCc2Eb1907D8`;

export const CAPSULE_TRIBE_ITEM = 257;

export const NETWORK = {
  TARGET_CHAIN_ID: 1,
  CHAIN_ID_TO_NAME: {
    1: "mainnet",
    3: "ropsten",
    4: "rinkeby",
    5: "goerli",
    42: "kovan",
    1337: "localhost",
  },
  CHAIN_ID_TO_STABLE_COIN: {
    1: "0x6b175474e89094c44da98b954eedeac495271d0f",
    42: "0x4F96Fe3b7A6Cf9725f59d353F723c1bDb64CA6Aa",
  },
  INFURA_ID: "632b4850f79f4f7f9ba2468897bdcbc0",
  PORTIS_ID: "",
  FORTMATIC_ID: "",
};

export const TRIBE_PRICES = [250, 500, 1000, 1500, 25, 50];
export const TRIBE_YIELDS = [1.5, 4, 9, 20, 0.12, 0.3];

export const TRIBE_STAKE_BONUS = [0, 7.5, 10, 12.5, 15, 17.5, 20];

export const TRIBE_NAMES = [
  "Lab",
  "Starship",
  "City",
  "Arena",
  "Mini Lab",
  "Mini Starship",
];

export const CONTRACTS = {
  AKC: "AKC",
  AKCCOIN: "AKCCoinV2",
  AKCCOIN_OLD: "AKCCoin",
  AKCCORE: "AKCCore",
  AKCRESERVE: "AKCReserve",
  BLUEBANANASERUM: "BlueBananaSerum",
  AKCCORESTAKEEXT: "AKCCoreMultiStakeExtension",
  AKCTRIBEMANAGER: "AKCTribeManagerUpgradeable",
  BLUEBANANASERUMSTAKE: "BlueBananaSerumStake",
  AKCMARKETPLACE: "AKCMarketPlaceUpgradable",
  TAXEXTENSION: "AKCTaxExtensionUpgradable",
  AKCRAFFLE: "AKCRaffleUpgradable",
  AKCRARITY: "AKCRarityStakingUpgradeable",
  OMEGA: "OmegaKongsClub"
};

export const ERRORS = {
  FEEDBACK: {
    UNEXPECTED_SERVER_ERROR: "An unexpected error occured.",
  },
  MESSAGES: [
    {
      error: "MetaMask Tx Signature: User denied transaction signature",
      message: "Error: You rejected the transaction.",
    },
    {
      error: "insufficient funds for gas * price + value",
      message: `Error: You don't have enough Ether in your wallet to pay for gas fees.`,
    },
    {
      error: "SPEC OUT OF BOUNDS",
      message: "Error: You are trying to buy a tribe that does not exist.",
    },
    {
      error: "INVALID TRIBE SPEC",
      message: "Error: You are trying to buy a tribe that does not exist.",
    },
    {
      error: "BALANCE TOO LOW",
      message: `Error: You don't have enough Alpha Coins to complete the purchase.`,
    },
    {
      error: "Marketplace: Sender does not own AKC",
      message: `Error: You need to hold at least 1 Alpha Kongs NFT to claim a whitelist spot.`,
    },
    {
      error: "Marketplace: Amount can not be zero",
      message: `Error: You need to select at least one spot.`,
    },
    {
      error: "Marketplace: Project does not exist",
      message: `Error: This project does not seem to be available at this time`,
    },
    {
      error: "Marketplace: Not enough spots left",
      message: `Error: Not enough supply left for your purchase`,
    },
    {
      error: "Marketplace: Project offering expired",
      message: `Error: Offer has expired and will be removed.`,
    },
    {
      error: "Marketplace: User exceeds maximum for project",
      message: `Error: Your claim exceeds the maximum spots per user limit.`,
    },
  ],
};

export const MORALIS = {
  CREDS: [
    {
      APP_ID: "l2dkcQuvB1vvInnA99a03SxpvM5XotUXNwUHtBKu",
      SERVER_URL: "https://chnjzednf0ro.usemoralis.com:2053/server",
    },
    {
      APP_ID: "KyBtbDOP2kTksGt0wk1heHUrxl0mJqfdqXVOEYeK",
      SERVER_URL: "https://wr39z5rslhh3.usemoralis.com:2053/server",
    },
    {
      APP_ID: "BPsfbad1ZEdVAnMkJmCs5Me2Gd4UnX2zH66aBF4Q",
      SERVER_URL: "https://pjaxjcjmxfmy.usemoralis.com:2053/server",
    },
    {
      APP_ID: "AjhoycArT2HHMHR1WmnUEiApJk3Q1YYeK1QqeurM",
      SERVER_URL: "https://9tb5e0nuzwrj.usemoralis.com:2053/server",
    },
  ],
  TABLE_NAMES: {
    UserTribeInfo: "UserTribeInfo",
    UserTribeDetail: "UserTribeDetail",
  },
  TABLE_COLUMNS: {
    UserTribeInfo: {
      Address: "Address",
      RPS: "RPS",
      TotalEarnings: "Total Earnings",
      LastPurchase: "Last Purchase",
      LastClaim: "Last Claim",
      Lab: "Lab",
      Starship: "Starship",
      City: "City",
      Arena: "Arena",
    },
    UserTribeDetail: {},
  },
};

export const FAQ_ITEMS = [
  {
    question: "How much daily yield do I earn?",
    mobileQuestion: "How much daily yield <br/>do I earn",
    answer: `Each tribe item has a different daily yield percentage. A lab yields you 2.5% per day, excluding boosts that you can get to get more  daily yield.
     The more tribe items you have, the more passive yield you earn. Unlock boosts to get earning bonuses of up to 40%. Yield is paid out live in your AKC dashboard and 
    refreshes live, so you do not have to wait a day to get your Alpha Coins. You can always buy and sell your Alpha Coins for other cryptocurrencies on Uniswap. Also, 
    don’t forget to invite your friends to earn free Alpha Coins.`,
  },
  {
    question: "What’s a tribe item, such as a Lab?",
    mobileQuestion: "What’s a tribe item, <br/>such as a Lab?",
    answer: `Each tribe has a specific item that they use, such as scientists that have a lab. Tribe items generate a daily yield in Alpha Coins for as long as you have the tribe item.
     They start generating Alpha Coins from the moment that you have a tribe item.`,
  },
  {
    question: "Are there risks involved?",
    mobileQuestion: "Are there risks involved?",
    answer: `<p>Definitely, and anyone that tells you different in the crypto-space is dishonest. 
    Any cryptocurrency and utility tokens can gain and lose value based on many known and unknown factors.&nbsp;<br><br>The price of Alpha Coins 
    (and any other cryptocurrency) can go up and down based on supply and demand, the overall market sentiment and many other factors.&nbsp;<br><br>
    Be aware of the fact that price fluctuations are regular in the crypto market, and that investing money that you can't afford to lose is always a bad idea.&nbsp;
    <br><br>We’re building an ecosystem for the future with an experienced team, and we’re focused on growth, many new utilities and adoption, but you never know 
    beforehand what the price of a cryptocurrency token will do. Keep that in mind, not only for Alpha Coins, but for any project in this space. <br><br>
    Read the disclaimer <a target="_blank" class="text-2xl white" href="https://www.alphakongsclub.com/terms-of-use-disclaimer" tabindex="0">HERE</a> for more information.</p>`,
  },
  {
    question: "Do I need an Alpha Kong NFT <br/>to start earning Alpha Coins?",
    mobileQuestion: `Do I need an Alpha <br/>Kong NFT to start <br/>earning Alpha Coins?`,
    answer: `Nope, you don’t! Anyone that’s excited about the AKC ecosystem and what’s to get involved, can join a tribe and start earning yield.
     We do this because the more support we get, the more the ecosystem will expand. However, staking your Alpha Kong NFT in a tribe item does reward you with greater staking rewards.`,
  },
  {
    question: "What’s a tribe?",
    mobileQuestion: "What’s a tribe?",
    answer: `A tribe is a group of Alpha Kongs with a unique personality, such as the Explorers. They collaborate in order to expand the AKC ecosystem.
     Each tribe has a tribe item that generates daily earnings. You do not need to have an Alpha Kong NFT to get a tribe item.`,
  },
  {
    question: "How can I buy Alpha Coins?",
    mobileQuestion: "How can I buy <br/> Alpha Coins?",
    answer: `You can buy and sell Alpha Coins <a target="_blank" class="text-2xl white" 
    href="https://app.uniswap.org/#/swap?chain=mainnet&outputCurrency=0x45d0749A4E355495c675673D4f3B36A67aA08046"><strong>here</strong></a> at any time. 
             A selling tax of 20% will be applied to stabilise the price of the Alpha Coins in the first few weeks. 
            This will gradually decrease over the first few weeks to 10%. We want to prevent spikes in prices as much as possible.`,
  },
  {
    question: "Can I own more tribe items?",
    mobileQuestion: "Can I own more <br/>tribe items?",
    answer: `Yes, you can. You generate more Alpha Coins on a daily basis by getting more items.`,
  },
  {
    question: `Can I get tribe items from <br/>more tribes, or just from one tribe?`,
    mobileQuestion: `Can I get tribe items from <br/>more tribes, or just <br/>from one tribe?`,
    answer: `Yes you can own multiple tribe items from different tribes. If you feel home in multiple tribes, feel free to join them and get multiple tribe items.`,
  },
  {
    question: "How does the AKC Ecosystem tokenomics work?",
    mobileQuestion: "How does the AKC Ecosystem <br/> tokenomics work?",
    answer: `<p>Our team consists of economists, tokenomics experts and DeFi experts with much experience in their craft. 
    Together, we’ve developed the AKC ecosystem tokenomics to become a success.<br>‍<br>1: 75% of the Alpha Coins from tribe item purchases go to the reward pool. 
    This is used and saved up for daily rewards, staking rewards and all earning bonuses that are unlocked by members.&nbsp;<br>‍<br>
    2: 15% is used for the following aspects:&nbsp;<br>- development of the platform. We are working with professionals constantly to make new utilities at a fast pace.&nbsp;
    <br>- buying alpha coins and burning them.&nbsp;<br>- affiliate rewards for members that invite their friends.&nbsp;<br>- earnings for the AKC team&nbsp;<br>
    - airdrops and giveaways: as you know, we like playing games and rewarding the community. We’re using a small cut to do fun giveaways and airdrops for the community.&nbsp;<br>‍
    <br>3: 10% goes to the liquidity pool, which provides more stability and liquidity to the price of Alpha Coins (50/50 split between alpha coins and ethereum)&nbsp;<br>‍
    <br>Alpha Kongs Club OpenSea commissions&nbsp;<br>‍<br>We’re also using 50% of the OS commissions for the following things:&nbsp;<br>‍<br>- 80% goes to the AKC liquidity pool&nbsp;
    <br>- 20% is used to buy alpha coins that we burn. <br>‍<br>Can the AKC Ecosystem tokenomics change over time?<br><br>The tokenomics of the ecosystem can change over time in order 
    to improve it for all participants. Our team is constantly monitoring how to provide more stability and better incentives. We also vote on many future changes with the community 
    through the DAO that’s being developed. Before we make any changes, we communicate it in the community and update it on Gitbook.&nbsp;<br><br>Read our Gitbook 
    <a class="text-2xl white" href="https://alphakongsclub8888.gitbook.io/akc-ecosystem/" target="_blank" tabindex="0">here</a> to gain more information about the ecosystem. 
    We’re always active on Discord to help out.&nbsp;<br><br>Is Tokenomics a new term for you?<br><br>Tokenomics basically means the economics of a crypto token. 
    If this is a new topic for you, we recommend learning more about it, especially if you are new in the cryptocurrency and NFT space. There are many credible sources 
    on Google, and informative videos on Youtube.&nbsp;</p>`,
  },
  {
    question: "What’s the price of one Alpha Coin?",
    mobileQuestion: "What’s the price of <br/>one Alpha Coin?",
    answer: `The price of one Alpha Coin is dependent upon the supply and demand of the coins. It can vary, just like other utility tokens in the market.`,
  },
  {
    question: "Can more Alpha Coins ever<br/>  be created?",
    mobileQuestion: "Can more Alpha Coins <br/>ever be created?",
    answer: `No, there is no possibility of creating more Alpha Coins, this is encoded in the contract. `,
  },
  {
    question: "What are the utilities right<br/>  now and in the near future?",
    mobileQuestion: `What are the utilities <br/>right now and in<br/>the near future?`,
    answer: `Anyone that joins a tribe gets to participate in collaborative games to expand the AKC ecosystem with new, exciting utilities. 
        <br/>
        <br/>
        You can stake your Alpha Kongs NFT in your tribe to boost daily earnings, or stake it for free for daily Alpha Coins in your dashboard. 
        <br/>
        <br/>
        The next step is creating a DAO with voting rights in the AKC ecosystem. Together with the community, we’ll vote on the next big moves. We’re focused on building. 
        <br/>
        <br/>
        Check the ecosystem roadmap on Gitbook to see which next steps we are eager to take. Most next steps will be proposed in the DAO. 
        <br/>
        <br/>
        The Omega Kongs will also play a valuable role in the ecosystem, this is still a mystery for now… 
        `,
  },
  {
    question: `What’s the benefit of owning<br/>  Alpha Kong NFTs in the AKC ecosystem? `,
    mobileQuestion: `What’s the benefit <br/>of owning Alpha Kong NFTs <br/>in the AKC ecosystem? `,
    answer: `You get increased daily yield from your tribe by staking your Alpha Kong in a tribe item.  
        <br/>
        <br/>
        Also, you can always stake your Alpha Kong NFT for free in the capsule, for a daily yield in Alpha Coins. This is less than staking them in a tribe item. 
        <br/>
        <br/>
        Example: If you have 10 labs, all of them yield 8% more daily earnings if you stake your Alpha Kong NFT in them. 
        `,
  },
  {
    question: `Can I always buy and sell Alpha Coins<br/>  for other cryptocurrencies?`,
    mobileQuestion: `Can I always buy <br/>and sell Alpha Coins <br/>for other cryptocurrencies?`,
    answer: `Yes you can, check Uniswap <a class="text-2xl white" target="_blank" href="https://app.uniswap.org/#/swap?chain=mainnet&outputCurrency=0x45d0749A4E355495c675673D4f3B36A67aA08046">
    <strong>HERE</strong></a>.`,
  },
  {
    question: "Where can I see the contract of<br/>  Alpha Coins? ",
    mobileQuestion: "Where can I see the <br/>contract of Alpha Coins?",
    answer: `Right here: <a class="text-2xl white" target="_blank" href="https://etherscan.io/address/0x45d0749A4E355495c675673D4f3B36A67aA08046">0x45d0749A4E355495c675673D4f3B36A67aA08046</a>`,
  },
  {
    question: "On which chain is the ecosystem built?",
    mobileQuestion: "n which chain is the<br/>ecosystem built?",
    answer: `Ethereum. Most people use ethereum, so it’s most easy to use. The contract will be optimised to minimise gas fees. There are other chains that have lesser gas fees, 
    but ease of use is more important to us.`,
  },
  {
    question: "How do I stake my Alpha Kong for free?",
    mobileQuestion: "How do I stake my <br/>Alpha Kong for free?",
    answer: `Any Alpha Kong NFT holder can stake their Alpha Kong NFT in a capsule for free. Doing so rewards them with free Alpha Coins on a daily basis.`,
  },
  {
    question: "How do I stake my Alpha Kong NFT<br/>  in the item of a tribe?",
    mobileQuestion: `How do I stake my Alpha <br/>Kong NFT in the <br/>item of a tribe?`,
    answer: `You can stake your Alpha Kong NFT in the item if your tribe for more rewards. You do this in your dashboard.`,
  },
  {
    question: "Can I become an affiliate partner? ",
    mobileQuestion: "Can I become <br/> an affiliate partner? ",
    answer: `Yes you can! We’ve got a great affiliate deal to benefit you and your friends that are eager to join. Open your dashboard and check the affiliate section on 
    how to get started. `,
  },
  {
    question: "What’s the ecosystem roadmap ",
    mobileQuestion: "What’s the ecosystem roadmap ",
    answer: `We’ve got many exciting things coming up for the AKC brand and the ecosystem. Read more about the specific AKC roadmap on Gitbook (open your dashboard and click 
      on more information). `,
  },
];

export const getInviteLink = (address) => {
  return `https://app.alphakongsclub.com/?invite=${address}`;
};

export const getEtherscanTxUrl = (hash) => `https://etherscan.io/tx/${hash}`;
