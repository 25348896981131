import React from "react";
import { createRoot } from "react-dom/client";
import { MoralisProvider } from "react-moralis";

import { MORALIS } from "./global_constants";

import { ProviderProvider } from "./context/provider";
import { providerReducer, initialState as provider } from "./reducers/provider";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "./styles/style.scss";

const random = Math.floor((Math.random() * 100) % MORALIS.CREDS.length);
let appId = MORALIS.CREDS[random].APP_ID;
let serverUrl = MORALIS.CREDS[random].SERVER_URL;

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <MoralisProvider appId={appId} serverUrl={serverUrl}>
    <ProviderProvider initialState={provider} reducer={providerReducer}>
      <App />
    </ProviderProvider>
  </MoralisProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
